.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $spacing-2xxl;
  padding: 0 0 0 $spacing-xl;

  &--left-aligned {
    justify-content: flex-start;
  }

  &--right-aligned {
    justify-content: space-between;
  }

  &--theme-light {
    background-color: white;

    @include nt-border-bottom;
  }

  &--theme-none {
    background-color: $color-nt-page-background;
  }

  &__title,
  &__toolbar {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__toolbar-wrapper {
    width: 100%;
    display: flex;
  }

  &__toolbar {
    width: 100%;
    max-width: 1366px;
    padding: 8px 0;
    justify-content: space-around;
  }

  &__title {
    margin-left: -16px;

    &__separator {
      @include nt-border-right;

      margin-right: $spacing-lg;
      height: 50%;
    }

    &__text {
      font-weight: $font-weight-light;
      font-size: 18px;
      line-height: 24px;
    }

    &__logo {
      cursor: pointer;
      min-width: 191px;
    }
  }

  &__navbar {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  &__subnav {
    display: none;
    width: max-content;
    right: 0;

    .subnav__item {
      outline: none;
    }

    .subnav__item:hover {
      background-color: $color-aqua-100;
    }
  }

  &__subnav-icon {
    transform: rotateZ(0);
    // transition: transform 0.3s;
  }

  &__navitem {
    position: relative;
    margin: 0 $spacing-lg;
    white-space: nowrap;
    outline: none;

    &:hover {
      .app-header__subnav {
        display: block;
      }

      .app-header__subnav-icon {
        transform: rotateZ(180deg);
      }
    }
  }
}
