.app-viewport {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;

  .pre-meeting-viewport {
    position: relative;
    width: 100%;
  }

  .host-viewport {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    pointer-events: none;

    * {
      pointer-events: all;
    }
  }

  .presentation-viewport {
    position: relative;
    display: flex;
    align-items: center;
    left: 200px;
    width: calc(100% - 200px);
    overflow-x: scroll;

    .presentation-view {
      position: relative;
      max-width: 1358px;
      min-width: 1150px;
      height: 100%;
      overflow-x: hidden;
      overflow-y: hidden;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
      background-color: white;
    }

    &--presenter {
      left: 200px;
      top: 64px;
      width: calc(100% - 200px);
      height: calc(100% - 64px);
    }

    &--host {
      // top: 64px;
      height: calc(100% - 64px);

      /* Open Drawer */
      left: 200px;
      width: calc(100% - 200px);
    }

    &--meeting-border:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }

    &.meeting-border--red:after {
      border: 4px solid $color-red-500;
    }

    &.meeting-border--orange:after {
      border: 4px solid $color-orange-500;
    }


    .sidebar {
      padding: 32px 32px 32px 48px;
      display: flex;
      flex-direction: column;
      height: 100%;

      .stacked-bar-chart {
        &__label {
          white-space: nowrap;
        }
      }

      .footer-entry-items{
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
      }
    }
  }

}
