@import '../abstracts/colors';

.quick-slides {
  &__footer {
    padding: 24px;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;

    .page-btn {
      border-radius: 50%;
      padding-top: 0.1em;

      i {
        font-size: 24px;
      }
    }
  }

  &__body {
//     display: flex;
//     justify-content: center;
//     align-items: center;
    .react-pdf__Page__annotations{
      display: none;
    }
  }

  .react-pdf__Page__canvas {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 85% !important;
      height: auto !important;
  }
}

.pdf-preview-pane {
  max-height: 80vh;
  overflow-y: scroll;

  &__container {
    max-width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .react-pdf__Page__annotations{
      display: none;
    }

  }

  .pdf-page {
    cursor: pointer;
    overflow: hidden;

    &__active,
    &:focus {
      border: 3px solid $color-aqua-500;
      outline: none;
    }
  }

  .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
  }
}
